import "../styles/index.scss"
import type { AppProps } from "next/app"
import "bootstrap/dist/css/bootstrap.min.css"


function MyApp({ Component, pageProps }: AppProps) {

  // Server-side Rendering wrapper for React Bootstrap
  // see https://react-bootstrap.github.io/getting-started/server-side-rendering/
  return (
    <Component {...pageProps} />
  )
}

export default MyApp
